.vjs-controls-enabled .vjs-big-play-button
    opacity: 1
    visibility: visible
    background-color: transparent !important
    background-image: url('/static/img/play.svg')
    background-repeat: no-repeat
    background-position: center
    background-size: contain
    color: transparent
    &:before
        text-shadow: none

.vjs-has-started .vjs-big-play-button, .amp-flush-skin.vjs-using-native-controls .vjs-big-play-button, .vjs-error .vjs-big-play-button, .vjs-waiting .vjs-big-play-button
    background-image: none
